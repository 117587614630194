import React from "react";
import default_image from "../../../assets/default_image.jpeg";

export default function OCVBlogGridPage(props: any) {
  const { viewData } = props;

  const [blogData, setBlogData] = React.useState<any>([]);

  React.useEffect(() => {
    fetch(viewData.url)
      .then((response) => response.json())
      .then((data) => setBlogData(data));
  }, [viewData.url]);

  let cols = viewData?.config?.numCols;

  const config = viewData?.config;

  return (
    <div
      id={props.anchorID}
      className={"pb-4 bg-cover h-auto " + config?.additionalTailwindStyles}
      style={{
        backgroundColor: viewData?.config?.bgColor
          ? viewData.config.bgColor
          : "#eee",
      }}
    >
      <div
        className={`w-10/12 mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-${
          cols * 2
        } pt-4`}
      >
        {blogData.map((item: any, index: number) => (
          <div
            key={item?.title + index}
            className="col-span-1 lg:col-span-2 mx-2 my-3 flex flex-col justify-between"
          >
            <div className="pb-4 ">
              <img
                alt=""
                src={
                  item?.images[0]?.large
                    ? item?.images[0]?.large
                    : default_image
                }
                className={`w-auto max-h-96 object-cover mx-auto ${
                  viewData?.config?.rounded ? "rounded-full" : null
                }`}
              />
            </div>
            {!viewData?.config?.showTitle && (
              <div
                className={`w-full mx-auto text-${viewData.config.titleColor} text-lg font-semibold lg:text-xl text-center pb-2`}
              >
                {`${item?.title}`}
              </div>
            )}
            {item?.extra && (
              <div className="flex flex-row justify-center space-x-2">
                {item?.extra?.length === 1 ? (
                  <a
                    href={item?.extra[0]?.url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <button
                      className={`border-2 rounded-full font-medium border-${viewData.config.buttonBorderColor} text-${viewData.config.buttonTextColor} p-2`}
                    >
                      {item?.extra[0]?.title}
                    </button>
                  </a>
                ) : (
                  item?.extra?.map((links: any) => (
                    <a
                      href={links?.url}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <button
                        className={`mx-auto border-2 rounded-full font-medium border-${viewData.config.buttonBorderColor} text-${viewData.config.buttonTextColor} p-2`}
                      >
                        {links?.title}
                      </button>
                    </a>
                  ))
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
