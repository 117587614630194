import React from "react";
import { Link } from "react-router-dom";
import BlogListWidgetPagination from "./BlogListWidgetPagination";
import moment from "moment";
import ReactLoading from "react-loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function BlogListWidget(props: any) {
  let {
    manifestData,
    onSubmit,
    displayDate,
    input,
    setInput,
    page,
    setPage,
    truncate,
    totalEntries,
    sortOrder,
    setSort,
    showSort,
  } = props;

  let handler = (e: any) => {
    if (e.key === "Enter") {
      onSubmit(input);
    }
  };

  const onChange = (e: any) => {
    setInput(e.target.value);
  };

  const selectChange = (e: any) => {
    setSort(e.target.value);
  };

  return !props.data.entries ? (
    <div style={{ height: "50vh" }}>
      <ReactLoading
        className="loading-centered"
        type={"bars"}
        color={"#FFF"}
        height={"10%"}
        width={"10%"}
      />
    </div>
  ) : (
    <>
      <div
        id={props.anchorID}
        className={
          "flex flex-col mx-auto sm:flex-row w-11/12 mt-4 " +
          manifestData?.config?.additionalTailwindStyles
        }
      >
        {manifestData?.config?.showSearch && (
          <div className="w-full md:w-9/12 mx-auto mb-2 sm:mb-0">
            <label htmlFor="textField" className="h-full w-full text-center">
              Search
              <div className="flex justify-center items-center">
                <input
                  id="textField"
                  type="text"
                  name="textField"
                  placeholder="Enter Query"
                  className="w-9/12 h-10 text-lg border-t-2 border-b-2 border-l-2 border-gray-400 rounded-l-2xl pl-2"
                  onChange={onChange}
                  onKeyPress={(e) => handler(e)}
                  value={input}
                />
                <button
                  aria-label="submit"
                  type="submit"
                  className="bg-gray-500 h-10 w-10 border-gray border-r-2 rounded-r-2xl z-0 "
                  onClick={() => {
                    onSubmit(input);
                  }}
                >
                  <FontAwesomeIcon
                    className="FeatureIcon"
                    icon={["fas", "search"]}
                  />
                </button>
              </div>
            </label>
          </div>
        )}

        {showSort && (
          <div className="flex flex-row mx-auto justify-center items-center">
            <label
              htmlFor="sort"
              className="h-full w-full text-center flex flex-col"
            >
              Select Sorting Order
              <select
                name="sort"
                id="sort"
                onChange={selectChange}
                className="h-10 px-3 rounded-2xl"
                defaultValue={sortOrder}
              >
                <option value="dateAsc">Date (Ascending Order)</option>
                <option value="dateDesc">Date (Descending Order)</option>
                <option value="nameAZ">Name (A to Z)</option>
                <option value="nameZA">Name (Z to A)</option>
              </select>
            </label>
          </div>
        )}
      </div>
      {totalEntries === 0 ? (
        <div className="text-center p-4 font-semibold text-xl">
          No results found. Please try another search term.
        </div>
      ) : (
        <div className="h-auto w-11/12 mx-auto bg-white rounded-xl my-6">
          <ul className="space-y-3">
            {props.data.entries.map((entry: any) => (
              <li
                key={entry._id.$id}
                className={`bg-white shadow py-4 px-3 sm:px-6 sm:rounded-md rounded-md grid grid-cols-1 ${
                  entry?.images[0] ? "sm:grid-cols-2" : ""
                } `}
              >
                <div className="flex mt-6">
                  <div className="h-full inline-block">
                    {displayDate ? (
                      <>
                        <h1 className="text-lg font-bold mt">
                          {manifestData?.config?.dateFormat
                            ? moment
                                .unix(entry.date?.sec)
                                .format(`${manifestData?.config?.dateFormat}`)
                                .toString()
                            : moment
                                .unix(entry.date?.sec)
                                .format("ddd, MMM DD, YYYY, hh:mm A z")
                                .toString()}
                        </h1>
                        <hr className="bg-yellow-400 w-2/12 py-0.5"></hr>
                      </>
                    ) : (
                      <></>
                    )}
                    <h1 className="text-lg font-extrabold mt-4">
                      {truncate ? (
                        <Link
                          className="OCVBlogListLinkOne font-bold mr-6 sm:mr-0"
                          to={{
                            pathname: `/${props.route}/${entry["_id"]["$id"]}`,
                          }}
                        >
                          {entry?.title}
                        </Link>
                      ) : (
                        <div className="OCVBlogListLinkOne font-bold mr-6 sm:mr-0">
                          {entry?.title}
                        </div>
                      )}
                    </h1>
                    {truncate ? (
                      <>
                        <p
                          className="mt-4"
                          dangerouslySetInnerHTML={{
                            __html:
                              entry?.content.length > 400
                                ? entry?.content.slice(0, 400) +
                                  "<span>...</span>"
                                : entry?.content,
                          }}
                        ></p>
                        {entry?.content.length > 400 && (
                          <Link
                            className="OCVBlogListLinkOne font-bold mr-6 sm:mr-0"
                            to={{
                              pathname: `/${props.route}/${entry["_id"]["$id"]}`,
                            }}
                          >
                            <p className="mt-3">Read More</p>
                          </Link>
                        )}
                      </>
                    ) : (
                      <p
                        className="mt-4"
                        dangerouslySetInnerHTML={{
                          __html: entry?.content,
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="mx-auto hidden sm:block">
                  <img
                    alt=""
                    src={entry?.images[0]?.small}
                    className="mx-auto"
                  />
                </div>
              </li>
            ))}
          </ul>
          <BlogListWidgetPagination
            totalEntries={totalEntries}
            limit={props.limit}
            pageRight={props.handlePageRight}
            pageLeft={props.handlePageLeft}
            searched={props.searched}
            page={page}
            setPage={setPage}
          />
        </div>
      )}
    </>
  );
}
