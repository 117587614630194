import React from 'react'

export default function PageView(props: any) {
  const [pageData, setPageData] = React.useState<any>([])
  React.useEffect(() => {
    fetch(props.manifestData['features'][props.viewData['feature']]['url'], {
      mode: 'cors',
    })
      .then((response) => response.json())
      .then((data) => {
        setPageData(data)
      })
  }, [props.manifestData, props.viewData])

  const config = props.viewData?.config
  let backgroundImageConfig = undefined

  if (config?.rgba1 && config?.rgba2 && config?.backgroundImageURL)
    backgroundImageConfig = `linear-gradient(${config.rgba1}, ${config.rgba2}), url(${config.backgroundImageURL})`
  else if (config?.rgba1 && config?.rgba2)
    backgroundImageConfig = `linear-gradient(${config.rgba1}, ${config.rgba2})`
  else if (config?.backgroundImageURL)
    backgroundImageConfig = `url(${config.backgroundImageURL})`

  return (
    <div
      id={props.anchorID}
      className={`bg-cover ${config?.additionalTailwindStyles}`}
      style={{
        backgroundColor: '#fff',
        backgroundImage: backgroundImageConfig,
      }}
    >
      {props.manifestData['features'][props.viewData['feature']]?.subtype[0] ===
        'imageAtTop' && (
        <>
          {pageData?.data?.images[0] ? (
            <div className="relative mb-4">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 flex flex-row justify-center">
                <img
                  className="relative object-contain w-full max-h-80"
                  src={pageData?.data?.images[0]?.large}
                  alt={props?.viewData?.alt}
                />
              </div>
            </div>
          ) : null}
          <div className="pt-8">
            <div className="text-center mx-auto max-w-7xl ">
              <h1 className="text-2xl flex flex-col items-center tracking-tight font-extrabold text-gray-900 sm:text-3xl md:text-4xl">
                <span className="block">{pageData?.data?.title}</span>
                <div
                  style={{ borderColor: props.viewData.divideColor }}
                  className="border-b-4 mt-3 w-1/5 justify-self-center"
                ></div>
              </h1>
              <div
                dangerouslySetInnerHTML={{ __html: pageData?.data?.content }}
                className="mt-3 text-sm text-gray-900 sm:text-base md:mt-5 md:text-lg "
              ></div>
            </div>
          </div>
        </>
      )}

      {props.manifestData['features'][props.viewData['feature']]?.subtype[0] !==
        'imageAtTop' && (
        <>
          <div className="py-8">
            <div className="text-center mx-auto max-w-7xl">
              <h1 className="text-2xl md:text-3xl lg:text-4xl flex flex-col items-center tracking-tight font-extrabold text-gray-900">
                <span className="block px-6 md:p-0">
                  {pageData?.data?.title}
                </span>
                <div
                  style={{ borderColor: props.viewData.divideColor }}
                  className="border-b-4 mt-3 w-1/5 justify-self-center"
                ></div>
              </h1>
              <div
                dangerouslySetInnerHTML={{ __html: pageData?.data?.content }}
                className="mt-3 text-sm text-gray-900 sm:text-base md:mt-5 md:text-lg w-10/12 2xl:w-3/4 mx-auto font-semibold"
              ></div>
            </div>
          </div>
          {pageData?.data?.images[0] ? (
            <div className="relative mt-4">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 flex flex-row justify-center">
                <img
                  className="relative object-contain w-full max-h-80"
                  src={pageData?.data?.images[0]?.large}
                  alt={props?.viewData?.alt}
                />
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  )
}
